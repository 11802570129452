export default {
	data: () => ({
		user: {
			current_password: "",
			password: "",
			password_confirmation: ""
		},

		error: ""
	}),
	methods: {
		resetForm() {
			// Reset the observer
			this.$refs.observer.reset();
			// Reset the user object
			this.user = {
				current_password: "",
				password: "",
				password_confirmation: ""
			};
		},

		update() {
			let _vm = this;
			// Send a POST request to change-password endpoint
			this.axios
				.post("/change-password", this.user)
				.then(function (response) {
					window.location.href = '/auth/login'
					history.back();     // equivalent to clicking back button
					history.go(-1);     // equivalent to history.back();

				})
				.catch(function () { });
		}
	},
	mounted() { }
};
